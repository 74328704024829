:root {
  --primary: #fd8;
  --primary-accent: #a85;
  --highlight: #ea2;

  --general-border: #cb9;
  --general-spacing: #987;
  --general-background: #876;

  --ui-border: #ca7;
  --ui-background: #765;
  --ui-background-clear: #7656;
  --ui-background-darker: #654;

  --header-background: #544;
  --gradient-dark: #975;
  --gradient-light: #eca;
  --gradient-light-bright: #fea;

  --border-width: 2px;
  --border-radius: 10px;
  --border-radius-inner: 7px;

  --box-shadow: 0px 4px 10px 0px var(--ui-background);
  --box-shadow-darker: 0px 4px 10px 3px var(--ui-background-darker);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  text-wrap: wrap;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

/* NODES */
.react-flow__node {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.react-flow__node.selected,
.react-flow__node:focus {
  box-shadow: var(--box-shadow-darker);
  > * {
    border-color: var(--highlight);
  }
}

/* EDGES */
.react-flow__edge {
  pointer-events: none !important ;
}

.react-flow__edge .react-flow__edge-path {
  stroke: var(--ui-border);
  stroke-width: 3;
}

.react-flow__edge.animated path {
  stroke: var(--highlight);
  stroke-width: 5;
}

.dimmed-path {
  > * {
    opacity: 0.25;
  }
}

/* HANDLES */
.react-flow__handle {
  opacity: 0;
}

/* CONTROLS */
.react-flow__controls button {
  background-color: var(--ui-background-darker);
  border: var(--border-width) solid var(--ui-border);
  color: var(--primary);
}

.react-flow__controls button:hover {
  background-color: var(--ui-background);
}

.react-flow__controls button:first-child {
  border-radius: 5px 5px 0 0;
}

.react-flow__controls button:last-child {
  border-radius: 0 0 5px 5px;
}

.react-flow__controls button path {
  fill: var(--primary);
}

/* ATTRIBUTION */
.react-flow__attribution {
  display: none;
}
